import { IInsuranceReport } from './../_models/IInsuranceReport';
import { ReportService } from './../_services/report.service';
import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';

@Injectable()
export class InsuranceResolver implements Resolve<IInsuranceReport[]> {
    // pageNumber = 1;
    // pageSize = 25;

    constructor(private reportService: ReportService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<IInsuranceReport[]> {
        let params: any = {};
        params.Export = "true";
        return this.reportService.GetInsurance(null, null, params).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/dashboard']);
                return of(null);
            })
        );
    }
}
