import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Claims } from 'app/_models/claims';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const expectedRoles = route.data.expectedRoles;

    if (this.oauthService.hasValidIdToken()) {
      const roles = (<Claims> this.oauthService.getIdentityClaims()).role;
      var result = roles.some(x => expectedRoles.includes(x));
      
      if(result)
          return true;
    }

    this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}