import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';

@Injectable()
export class EnviromentService {
    private appConfig;

    constructor(private http: HttpClient) { }

    loadAppConfig(oAuthConfig: OAuthModuleConfig) {
        let env = "development"
        if(window.location.origin === "http://localhost:4200" || window.location.origin === "https://staging.diamond.fastsigns.com" || window.location.origin === "https://diamond.staging.fastsigns.com"){
          env = "development"
        }
        if(window.location.origin === "https://staging.diamond.fastsigns.com" || window.location.origin === "https://diamond.staging.fastsigns.com"){
          env = "staging"
        }
        if(window.location.origin === "https://fs-diamond-codeauthority.web.app" || window.location.origin === "https://codeauthority.diamond.fastsigns.com"){
          env = "codeauthority"
        }
        if(window.location.origin === "https://diamond.fastsigns.com"){
          env = "production"
        }
        return this.http.get('/assets/data/appConfig.' + env + '.json')
                    .toPromise()
                    .then(d => {
                        this.appConfig = d;
                        oAuthConfig.resourceServer.allowedUrls = this.appConfig.allowedUrls;
                    });
        // return this.http.get('/assets/data/env/enviroment.json')
        //     .toPromise()
        //     .then(data => {
        //         if (data == null) {
        //            return this.http.get('/assets/data/appConfig.development.json')
        //                 .toPromise()
        //                 .then(d => {
        //                     this.appConfig = d;
        //                     oAuthConfig.resourceServer.allowedUrls = this.appConfig.allowedUrls;
        //                 });
        //         }
        //         return this.http.get('/assets/data/appConfig.' + (<any>data).env + '.json')
        //             .toPromise()
        //             .then(d => {
        //                 this.appConfig = d;
        //                 oAuthConfig.resourceServer.allowedUrls = this.appConfig.allowedUrls;
        //             });

        //     });
    }

    public getConfig() {
        return this.appConfig;
    }
}