import { EnviromentService } from './enviroment.service';
import { EmailAddress } from './../_models/EmailAddress';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class EmailaddressService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiV2Url;
    }

    add(add: EmailAddress): Observable<number> {
      return this.http.post<number>(this.baseUrl + 'emailaddresses', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'emailaddresses/' + id);
    }

    update(update: EmailAddress): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'emailaddresses/' + update.id, update);
    }

    get(id: number): Observable<EmailAddress> {
      return this.http.get<EmailAddress>(this.baseUrl + '/emailaddresses/' + id);
    }

    getByCenterId(centerId: number): Observable<EmailAddress[]> {
        return this.http.get<EmailAddress[]>(this.baseUrl + 'centers/' + centerId + '/emailaddresses');
    }

  }
