import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageEmail } from 'app/_models/MessageEmail';
import { Template } from 'app/_models/ITemplate';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';
import { AddTemplate } from 'app/_models/AddTemplate';
import { Campaign } from 'app/_models/Campaign';
import { CampaignRecipient } from 'app/_models/CampaignRecipient';

@Injectable()
export class MessageService {
    baseUrl = '';
    

    constructor(private http: HttpClient, private enviromentService: EnviromentService, ) {
        this.baseUrl = enviromentService.getConfig().apiEmail;
    }

    email(add: MessageEmail): Observable<number> {
        add.useSmtp = false;
      return this.http.post<number>(this.baseUrl + 'emails', add);
    }

    getTemplates(page?, itemsPerPage?): Observable<PaginatedResult<Template[]>> {
      const paginatedResult: PaginatedResult<Template[]> = new PaginatedResult<Template[]>();
      let params = new HttpParams();

      if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
      }
      return this.http.get<Template[]>(this.baseUrl + 'templates/', { observe: 'response', params })
          .pipe(
              map(response => {
                  paginatedResult.result = response.body;
                  if (response.headers.get('Pagination') != null) {
                      paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                  }
                  return paginatedResult;
              })
          );
    }

    deleteTemplate(id: number): Observable<Boolean>{
        return this.http.delete<Boolean>(this.baseUrl + 'templates/' + id);
    }

    addTemplate(template: AddTemplate): Observable<number>{
        return this.http.post<number>(this.baseUrl + 'templates', template);
    }

    updateTemplate(template: Template): Observable<boolean> {
        return this.http.put<boolean>(this.baseUrl + 'templates/' + template.id, template);
    }

    getCampaignById(id: number): Observable<Campaign> {
        return this.http.get<Campaign>(this.baseUrl + 'campaigns/' + id);
    }

    getCampaigns(page?, itemsPerPage?, exporting?): Observable<PaginatedResult<Campaign[]>> {
        const paginatedResult: PaginatedResult<Campaign[]> = new PaginatedResult<Campaign[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        if(exporting != null){
            params = params.append('export', exporting);
        }


        return this.http.get<Campaign[]>(this.baseUrl + 'campaigns', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    return paginatedResult;
                })
            );
    }

    getEmails(page?, itemsPerPage?, centerNumber?): Observable<PaginatedResult<MessageEmail[]>> {
        const paginatedResult: PaginatedResult<MessageEmail[]> = new PaginatedResult<MessageEmail[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        if(centerNumber != null)
        {
            params = params.append('centerNumber', centerNumber)
        }

        return this.http.get<MessageEmail[]>(this.baseUrl + 'emails', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    return paginatedResult;
                })
            );
    }

    getEmail(id): Observable<MessageEmail> {
        return this.http.get<MessageEmail>(this.baseUrl + 'emails/' + id);
    }

    addCampaign(add: Campaign): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'campaigns', add);
    }

    updateCampaign(update: Campaign): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'campaigns/' + update.id, update);
    }

    deleteCampaign(id: number): Observable<Boolean> {
        return this.http.delete<Boolean>(this.baseUrl + 'campaigns/' + id);
    }

    sendCampaign(campaignId: number): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'campaigns/'+ campaignId + '/send', null);
    }

    getCampaignRecipientsByCampaignId(campaignId: number, page?, itemsPerPage?): Observable<PaginatedResult<CampaignRecipient[]>> {
        const paginatedResult: PaginatedResult<Campaign[]> = new PaginatedResult<Campaign[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        return this.http.get<CampaignRecipient[]>(this.baseUrl + 'campaigns/' + campaignId + '/recipients', { observe: 'response', params })
        .pipe(
            map(response => {
                paginatedResult.result = response.body;
                if (response.headers.get('Pagination') != null) {
                    paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                }
                return paginatedResult;
            })
        );
    }

    deleteRecipient(campaignId: number, recipientId: number): Observable<Boolean>{
        return this.http.delete<Boolean>(this.baseUrl + 'campaigns/' + campaignId + '/recipients/' + recipientId);
    }

    updateRecipient(update: CampaignRecipient): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'campaigns/' + update.campaignId + '/recipients/' + update.id, update);
    }

    addRecipient(add: CampaignRecipient): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'campaigns/' + add.campaignId + '/recipients/', add);
    }

    addManyRecipients(add: CampaignRecipient[], campaignId: number): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'campaigns/' + campaignId + '/manyRecipients/', add);
    }

    getPreview(campaignId: number, recipientId: number): Observable<any> {
        const responseType = 'text';

        const options = {
            headers: new HttpHeaders()
        };
        options['responseType'] = responseType;
        return this.http.get<any>(this.baseUrl + 'campaigns/' + campaignId + '/recipients/' + recipientId + '/preview',
        options);
    }

}
