import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAuditLog } from 'app/_models/IAduitLog';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class AuditLogService {

    baseUrl = '';
  
    constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
      this.baseUrl = enviromentService.getConfig().apiV2Url;
    }
    
    getAudits(page?, itemsPerPage?, pParams?): Observable<PaginatedResult<IAuditLog[]>> {
      const paginatedResult: PaginatedResult<IAuditLog[]> = new PaginatedResult<IAuditLog[]>();
  
      let params = new HttpParams();
  
      if (page != null && itemsPerPage != null) {
        params = params.append('pageNumber', page);
        params = params.append('pageSize', itemsPerPage);
      }
  
      if (pParams != null && pParams.table != null) {
        params = params.append('Table', pParams.table);
      }
  
      if (pParams != null && pParams.column != null && pParams.column.length > 0) {
        params = params.append('Column', pParams.column.trim());
      }
  
      if (pParams != null && pParams.row != null && pParams.row > 0) {
        params = params.append('Row', pParams.row);
      }
  
      if (pParams != null && pParams.changeDate != null) {
        params = params.append('ChangeDate', pParams.changeDate);
      }
  
      if (pParams != null && pParams.changedBy != null && pParams.changedBy.length > 0) {
        params = params.append('ChangedBy', pParams.changedBy);
      }
  
      if (pParams != null && pParams.oldValue != null && pParams.oldValue.length > 0) {
        params = params.append('OldValue', pParams.oldValue);
      }
  
      if (pParams != null && pParams.newValue != null && pParams.newValue.length > 0) {
        params = params.append('NewValue', pParams.newValue);
      }

      if (pParams != null && pParams.centerId != null && pParams.centerId > 0) {
        params = params.append('CenterId', pParams.centerId);
      }

      if(pParams != null && pParams.rows != null  && pParams.rows.length > 0){
         let rows = pParams.rows.join();
         params = params.append('rows', rows);
      }
  
      return this.http.get<IAuditLog[]>(this.baseUrl + 'auditlogs', { observe: 'response', params})
        .pipe(
          map(response => {
            paginatedResult.result = response.body;
            if (response.headers.get('Pagination') != null) {
              paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
            }
            return paginatedResult;
          })
        );
    }

}
