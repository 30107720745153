import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { FranchiseDevelopment } from 'app/_models/FranchiseDevelopment';

@Injectable()
export class FranchiseDevelopmentService {
  
  baseUrl = '';
  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(add: FranchiseDevelopment): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'franchiseDevelopments', add);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'franchiseDevelopments/' + id);
  }

  update(update: FranchiseDevelopment): Observable<number> {
      return this.http.put<number>(this.baseUrl + 'franchiseDevelopments/' + update.id, update);
  }

  get(id: number): Observable<FranchiseDevelopment> {
    return this.http.get<FranchiseDevelopment>(this.baseUrl + 'franchiseDevelopments/' + id);
  }

  getByCenterId(centerId: number): Observable<FranchiseDevelopment[]>{
      return this.http.get<FranchiseDevelopment[]>(this.baseUrl + 'centers/' + centerId + '/franchiseDevelopments');
  }

}
