import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Certification } from 'app/_models/Certification';

@Injectable()
export class CertificationService {

  baseUrl = '';
  
  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(add: Certification): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'certifications', add);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'certifications/' + id);
  }

  update(update: Certification): Observable<number> {
      return this.http.put<number>(this.baseUrl + 'certifications/' + update.id, update);
  }

  get(id: number): Observable<Certification> {
    return this.http.get<Certification>(this.baseUrl + '/certifications/' + id);
  }

  getByCenterId(centerId: number): Observable<Certification[]>{
      return this.http.get<Certification[]>(this.baseUrl + 'centers/' + centerId + '/certifications');
  }

}
