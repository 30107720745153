import { Ownership } from './../../_models/mfa/Ownership';
import { MasterFranchiseOwner } from './../../_models/mfa/MasterFranchiseOwner';
import { AddMasterFranchiseOwner } from './../../_models/mfa/AddMasterFranchiseOwner';

import { EnviromentService } from './../enviroment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class MasterFranchiseOwnerService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
    }

    add(add: AddMasterFranchiseOwner): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'owners', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'owners/' + id);
    }

    update(update: MasterFranchiseOwner): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'owners/' + update.id, update);
    }

    get(id: number): Observable<MasterFranchiseOwner> {
        return this.http.get<MasterFranchiseOwner>(this.baseUrl + 'owners/' + id);
    }

    getMasterFranchiseOwners(page?, itemsPerPage?, MasterFranchiseOwnerParams?): Observable<PaginatedResult<MasterFranchiseOwner[]>> {
        const paginatedResult: PaginatedResult<MasterFranchiseOwner[]> = new PaginatedResult<MasterFranchiseOwner[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }


        return this.http.get<MasterFranchiseOwner[]>(this.baseUrl + 'owners', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    return paginatedResult;
                })
            );
    }


    getByMfaId(id: number): Observable<MasterFranchiseOwner[]> {
        return this.http.get<MasterFranchiseOwner[]>(this.baseUrl + 'MasterFranchiseAgreements/' + id + '/owners/');
    }

    addOwnership(id: number, ownership: Ownership): Observable<boolean> {
        return this.http.post<boolean>(this.baseUrl + 'owners/' + id + '/ownerships/', ownership);
    }

    getOwnerships(id: number): Observable<Ownership[]> {
        return this.http.get<Ownership[]>(this.baseUrl + 'owners/' + id + '/ownerships/');
    }

    removeOwner(id: number, ownershipId: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'owners/' + id + '/ownerships/' + ownershipId);
    }

}