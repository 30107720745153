import { FranchiseDisclosure } from './../../_models/mfa/FranchiseDisclosure';
import { AddFranchiseDisclosure } from './../../_models/mfa/AddFranchiseDisclosure';

import { EnviromentService } from './../enviroment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class FranchiseDisclosureService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
     }

    add(add: AddFranchiseDisclosure): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'FranchiseDisclosures', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'FranchiseDisclosures/' + id);
    }

    update(update: FranchiseDisclosure): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'FranchiseDisclosures/' + update.id, update);
    }

    get(id: number): Observable<FranchiseDisclosure> {
        return this.http.get<FranchiseDisclosure>(this.baseUrl + 'FranchiseDisclosures/' + id);
    }

    getFranchiseDisclosures(page?, itemsPerPage?, FranchiseDisclosureParams?): Observable<PaginatedResult<FranchiseDisclosure[]>> {
        const paginatedResult: PaginatedResult<FranchiseDisclosure[]> = new PaginatedResult<FranchiseDisclosure[]>();
    
        let params = new HttpParams();
    
        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
    
    
        return this.http.get<FranchiseDisclosure[]>(this.baseUrl + 'FranchiseDisclosures', { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }


    getByMfaId(id: number): Observable<FranchiseDisclosure[]> {
        return this.http.get<FranchiseDisclosure[]>(this.baseUrl + 'MasterFranchiseAgreements/' + id + '/FranchiseDisclosures/');
    }

}