<div class="wrapper">
    <ng-progress [color]="'red'" [ease]="'linear'"></ng-progress>
    <div class="sidebar" data-color="red">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
