
import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';
import { MasterFranchiseAgreement } from 'app/_models/mfa/MasterFranchiseAgreement';
import { MasterFranchiseAgreementService } from 'app/_services/mfa/master-franchise-agreement.service';

@Injectable()
export class MasterFranchiseAgreementResolver implements Resolve<MasterFranchiseAgreement[]> {
    // pageNumber = 1;
    // pageSize = 25;

    constructor(private masterFranchiseAgreementService: MasterFranchiseAgreementService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MasterFranchiseAgreement[]> {
        let params: any = {};
        params.Export = "true";
        return this.masterFranchiseAgreementService.getMasterFranchiseAgreements(null, null, params).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/dashboard']);
                return of(null);
            })
        );
    }
}

