import { AddEmailAddress } from './../_models/AddEmailAddress';
import { map } from 'rxjs/operators';
import { PaginatedResult } from './../_models/Pagination';
import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Contact } from 'app/_models/Contact';
import { Observable, of } from 'rxjs';
import { AddTag } from 'app/_models/AddTag';
import { CenterV2 } from 'app/_models/CenterV2';
import { PhoneNumber } from 'app/_models/PhoneNumber';
import { Address } from 'app/_models/Address';
import { AddAddress } from 'app/_models/AddAddress';
import { EmailAddress } from '../_models/EmailAddress';

@Injectable()
export class ContactService {

  baseUrl = '';

  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(contact: Contact): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'contacts', contact);
  }

  addContactLink(id: number, centerId: number): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'centers/' + centerId + '/contacts/' + id, null);
  }

  removeContactLink(id: number, centerId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'centers/' + centerId + '/contacts/' + id);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'contacts/' + id);
  }

  update(contact: Contact): Observable<number> {
      return this.http.put<number>(this.baseUrl + 'contacts/' + contact.id, contact);
  }

  get(id: number): Observable<Contact> {
    return this.http.get<Contact>(this.baseUrl + 'contacts/' + id);
  }

  getByCenterId(centerId: number): Observable<Contact[]> {
      return this.http.get<Contact[]>(this.baseUrl + 'centers/' + centerId + '/contacts');
  }

  doesContactExist(name: string): Observable<Boolean> {
    return new Observable((observer:any) => {
      this.getContactByName(name)
      .subscribe({
        next: (value) => {
          return value == [] ? true: false;
        }
      })
    })
  }

  getContactByName(name: string): Observable<Contact[]> {
    let params = new HttpParams();

    params = params.append('Name', name);

    return this.http.get<Contact[]>(this.baseUrl + 'contacts', { observe: 'response', params})
      .pipe(
        map(response => {
          let result: Contact[] = response.body;
          if (result[0] && result[0].name && result[0].name.toLowerCase() == name.toLowerCase()) {
            return result;
          } else {
            return [];
          }
        })
      );
  }

  getContacts(page?, itemsPerPage?, contactParams?): Observable<PaginatedResult<Contact[]>> {
    const paginatedResult: PaginatedResult<Contact[]> = new PaginatedResult<Contact[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (contactParams != null && contactParams.Name != null && contactParams.Name.length > 0) {
      params = params.append('Name', contactParams.Name);
    }

    if (contactParams != null && contactParams.BirthDayMonth != null && contactParams.BirthDayMonth.length > 0) {
      params = params.append('BirthDayMonth', contactParams.BirthDayMonth);
    }

    if (contactParams != null && contactParams.City != null && contactParams.City.length > 0) {
      params = params.append('City', contactParams.City);
    }

    if (contactParams != null && contactParams.State != null && contactParams.State.length > 0) {
      params = params.append('State', contactParams.State);
    }

    if (contactParams != null && contactParams.Zip != null && contactParams.Zip.length > 0) {
      params = params.append('Zip', contactParams.Zip);
    }

    if (contactParams != null && contactParams.Country != null && contactParams.Country.length > 0) {
      params = params.append('Country', contactParams.Country);
    }

    if (contactParams != null && contactParams.PhoneNumber != null && contactParams.PhoneNumber.length > 0) {
      const encodedPhone = encodeURIComponent(contactParams.PhoneNumber);
      // console.log(`Encoded: ${contactParams.PhoneNumber} as [${encodedPhone}].`);
      params = params.append('PhoneNumber', encodedPhone);
    }

    return this.http.get<Contact[]>(this.baseUrl + 'contacts', { observe: 'response', params})
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

  getCentersByContactLink(id: number): Observable<CenterV2[]>{
    return this.http.get<CenterV2[]>(this.baseUrl + 'contacts/' + id + '/centers?linkType=contact');
  }

  getCentersByShareHolderLink(id: number): Observable<CenterV2[]>{
    return this.http.get<CenterV2[]>(this.baseUrl + 'contacts/' + id + '/centers?linkType=shareholder');
  }

  deleteTag(contactId: number, id: number, centerId:number) {
    if(centerId == null)
      return this.http.delete(this.baseUrl + 'contacts/' + contactId + '/tags/' + id);

    return this.http.delete(this.baseUrl + 'centers/' + centerId + '/contacts/' + contactId + '/tags/' + id)
  }

  addTag(add: AddTag, contactId: number, centerId: number): Observable<number> {

    if(centerId == null)
      return this.http.post<number>(this.baseUrl + 'contacts/' + contactId + '/tags', add);
    
    return this.http.post<number>(this.baseUrl + 'centers/' + centerId + '/contacts/' + contactId + '/tags', add);
  }

  addPhoneNumber(add: PhoneNumber, contactId: number): Observable<number> {
    
    return this.http.post<number>(this.baseUrl + 'contacts/' + contactId + '/phonenumbers', add);
  }

  updatePhoneNumber(update: PhoneNumber, contactId: number): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + 'contacts/' + contactId + '/phonenumbers/' + update.id, update);
  }

  deletePhoneNumber(id: number, contactId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'contacts/' + contactId + '/phonenumbers/' + id);
  }

  getPhoneNumbers(contactId:number): Observable<PhoneNumber[]>{
    return this.http.get<PhoneNumber[]>(this.baseUrl + 'contacts/' + contactId + '/phonenumbers/');
  }

  getAddresses(contactId: number): Observable<Address[]>{
    return this.http.get<Address[]>(this.baseUrl + 'contacts/' + contactId + '/addresses');
  }

  deleteAddress(contactId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'contacts/' + contactId + '/addresses/' + id);
  }

  addAddress(address: AddAddress): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'contacts/' + address.contactId + '/addresses', address);
  }

  updateAddress(address: Address): Observable<number> {
    return this.http.put<number>(this.baseUrl + 'contacts/' + address.contactId + '/addresses/' + address.id, address);
  }

  getEmails(contactId:number): Observable<EmailAddress[]>{
    return this.http.get<PhoneNumber[]>(this.baseUrl + 'contacts/' + contactId + '/emailaddresses/');
  }

  deleteEmail(contactId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'contacts/' + contactId + '/emailaddresses/' + id);
  }

  addEmail(email: AddEmailAddress, contactId: number): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'contacts/' + contactId + '/emailaddresses', email);
  }

  updateEmail(email: EmailAddress, contactId: number): Observable<number> {
    return this.http.put<number>(this.baseUrl + 'contacts/' + contactId + '/emailaddresses/' + email.id, email);
  }

  getTagShortCodes(): Observable<string[]>
  {
    return this.http.get<string[]>(this.baseUrl + 'contacts/tags/shortcodes');
  }
  getTagTypes(): Observable<string[]>
  {
    return this.http.get<string[]>(this.baseUrl + 'contacts/tags/types');
  }
}
