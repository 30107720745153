import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { TaxInfo } from '../_models/TaxInfo';
import { AddTaxInfo } from '../_models/AddTaxInfo';

@Injectable()
export class TaxInfoService {
  baseUrl = '';
  
  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(add: AddTaxInfo): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'taxInfos', add);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'taxInfos/' + id);
  }

  update(update: TaxInfo): Observable<number> {
      return this.http.put<number>(this.baseUrl + 'taxInfos/' + update.id, update);
  }

  get(id: number): Observable<TaxInfo> {
    return this.http.get<TaxInfo>(this.baseUrl + '/taxInfos/' + id);
  }

  getByCenterId(centerId: number): Observable<TaxInfo[]>{
      return this.http.get<TaxInfo[]>(this.baseUrl + 'centers/' + centerId + '/taxInfos');
  }

}
