import { AlertifyService } from './../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from '../../../node_modules/rxjs/operators';
import { IComplianceReport } from '../_models/IComplianceReport';
import { ReportService } from '../_services/report.service';

@Injectable()
export class ComplianceReportResolver implements Resolve<IComplianceReport[]> {

    constructor(private reportService: ReportService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<IComplianceReport[]> {
        return this.reportService.getComplianceReport().pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/dashboard']);
                return of(null);
            })
        );
    }
}
