import { DetailItem } from '../_models/DetailItem';
import { DetailGroup } from './../_models/DetailGroup';
import { DetailOption } from './../_models/DetailOption';
import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { Detail } from 'app/_models/Detail';
import { Observable } from 'rxjs';
import { AddDetailType } from './../_models/AddDetailType';

@Injectable()
export class DetailService {

    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
        this.baseUrl = enviromentService.getConfig().apiV2Url;
    }

    getDetailTypes(): Observable<Detail[]> {
        return this.http.get<Detail[]>(this.baseUrl + 'details/types');
    }

    addDetailType(type: AddDetailType): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'details/types', type);
    }

    deleteDetailType(type: string): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'details/types/' + type);
    }

    getDetailOTypeptions(type: string): Observable<DetailOption[]> {
        return this.http.get<DetailOption[]>(this.baseUrl + 'details/types/'  + type + '/options');
    }

    addDetailTypeOption(type: string, detailOption: DetailOption): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'details/types/' + type + '/options', detailOption);
    }

    deleteDetailTypeOption(type: string, optionId: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'details/types/' + type + '/options/' + optionId);
    }

    getDetailGroupsByCenterId(centerId: number): Observable<DetailGroup[]> {
        return this.http.get<DetailGroup[]>(this.baseUrl + 'centers/' + centerId + '/detailgroups');
    }

    addDetailGroupByCenterId(centerId: number, type: Detail): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'centers/' + centerId + '/detailgroups', type);
    }

    getDetailGroupDetailsByCenterId(centerId: number, detailGroupId: number): Observable<DetailItem[]> {
        return this.http.get<DetailItem[]>(this.baseUrl + 'centers/' + centerId + '/detailgroups/' + detailGroupId + '/details');
    }

    deleteDetailGroupByCenterId(centerId: number, detailGroupId: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'centers/' + centerId + '/detailgroups/' + detailGroupId);
    }

    updateDetailByGroup(centerId: number, detailGroupId: number, detailId: number, detail: DetailItem): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'centers/'
        + centerId + '/detailgroups/' + detailGroupId + '/details/' + detailId, detail);
    }

}
