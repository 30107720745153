import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { EnviromentService } from './enviroment.service';
import { SalesResponse } from 'app/_models/sales/SalesResponse';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { SalesAmount } from 'app/_models/SalesAmount';
import { CenterSalesAmount } from 'app/_models/CenterSalesAmount';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { start } from 'repl';


 
@Injectable({
  providedIn: 'root'
})
export class SalesService {
 
  constructor(private apollo: Apollo, httpLink: HttpLink, private enviromentService: EnviromentService) {
    const auth = setContext((operation, context) => ({
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
    }));

    const uri = enviromentService.getConfig().apiSales

    apollo.create({
      link: ApolloLink.from([
        auth,
        httpLink.create({ uri }),
        onError(({ graphQLErrors, operation, forward }): any => {
          // User access token has expired
          if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
            return new Observable(observer => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  // Re-add old headers
                  ...headers,
                  // Switch out old access token for new one
                  Authorization: `Bearer ${sessionStorage.getItem('access_token')}` || null,
                }
              }));
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              return forward(operation).subscribe(subscriber);
            })
          }
        })
      ]),
      cache: new InMemoryCache()
    })
  }

  GetYearOverYearSales(centerNumber, startYear, endYear) : Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo.query<SalesResponse>({
      query: gql`
      query center($centerNumber: ID!, $startYear: Int, $endYear: Int){
        center(centerNumber: $centerNumber){
          centerNumber
          webNumber
          monthlySales(input: {startYear: $startYear, endYear: $endYear}){
            sale{
              date
              businessDays
              amount
            }
            comparableSale{
              date
              businessDays
              amount
            }
          percentChange
          aggregation
          }
        }
      }`,
      variables: { centerNumber: centerNumber, startYear: startYear, endYear: endYear}
    }).toPromise();
  }

  getSalesByCountryByYear(countryCode, StartYear, EndYear): Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo.query<SalesResponse>({
      query: gql`
      query country($countryCode: String!, $startYear: Int, $endYear: Int){
        country(countryCode: $countryCode) {
          countryCode
          countryName
           monthlySales(input: {startYear: $startYear, endYear: $endYear}){
            sale {
              date
              amount
            }
          } 
        }
      }`,
      variables: { countryCode: countryCode, startYear: StartYear, endYear: EndYear }
    }).toPromise(); 
 }

  getAllCenterMonthlySales(countryCode, StartYear, EndYear): Promise<ApolloQueryResult<SalesResponse>> {
    return this.apollo.query<SalesResponse>({
      query: gql`
      query centersByCountry($countryCode: String!, $startYear: Int, $endYear: Int){
        centersByCountry(input: {countryCode: $countryCode, startYear: $startYear, endYear: $endYear}){
         centerNumber
         webNumber
         vanityName
         countryCode
         countryName
         regionName
         stateProvinceCode
         stateProvinceName
         city
         monthlySales{
           sale {
             date
             amount
             businessDays
           }
           comparableSale {
             date
             amount
             businessDays
           }
           percentChange
           aggregation
         }
       }
      }`,
      variables: { countryCode: countryCode, startYear: StartYear, endYear: EndYear }
    }).toPromise(); 
  }
}

// import { Royalty } from '../_models/IRoyalty';
// import { EnviromentService } from './enviroment.service';
// import { Observable } from 'rxjs';
// import { Injectable } from '@angular/core';
// import { HttpClient, HttpParams } from '@angular/common/http';
// import { SalesIndustry } from '../_models/ISalesIndustry';
// import { SalesItem } from '../_models/SalesItem';
// import { SalesAmount } from '../_models/SalesAmount';
// import { CenterSalesAmount } from 'app/_models/CenterSalesAmount';
// import { YearOverYearSales } from 'app/_models/YearOverYearSales';
// import { OAuthService } from 'angular-oauth2-oidc';
// @Injectable({
//     providedIn: 'root'
// })

// export class SalesService {
//     baseUrl = '';
//     salesv2Url = '';
//     constructor(private http: HttpClient, private enviromentService: EnviromentService,  private oauthService: OAuthService) {
//         this.baseUrl = enviromentService.getConfig().apiSales;
//         this.salesv2Url = enviromentService.getConfig().apiSalesv2;
//      }

//     getSalesYearly(startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/yearly/center', { params: params });
//     }

//     getSalesMonthly(startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/monthly', { params: params });
//     }
//     getSalesDaily(startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/daily', { params: params });
//     }

//     getCountryCodes(): Observable<string[]>{

//         return this.http.get<string[]>(this.baseUrl + 'sales/countrycodes',);
//     }

//     getSalesYears(): Observable<number[]>{

//         return this.http.get<number[]>(this.baseUrl + 'sales/year',);
//     }

//     getSalesHourly(startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/hourly', { params: params });
//     }

//     getSalesOrders(centerNumber, startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/center/' + centerNumber + '/orders', { params: params });
//     }

//     getSalesByCenterMonthly(centerNumber, startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/center/' + centerNumber + '/monthly', { params: params });
//     }

//     getSalesByCenterDaily(centerNumber, startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/center/' + centerNumber + '/daily', { params: params });
//     }

//     getSalesByCenterHourly(centerNumber, startDate, endDate): Observable<SalesItem[]>{
//         let params = new HttpParams();
//         if(startDate != null)
//         {
//             params = params.append('startDate', startDate);
//         }
//         if(endDate != null)
//         {
//             params = params.append('endDate', endDate);
//         }

//         return this.http.get<SalesItem[]>(this.baseUrl + 'sales/center/' + centerNumber + '/hourly', { params: params });
//     }

//     getSalesByCenter(centerNumber): Observable<SalesAmount[]>{
//         let params = new HttpParams();
//         let response =  this.http.get<SalesAmount[]>(this.salesv2Url + 'Sales/' + centerNumber, { params: params });
//         //let response =  this.http.get(this.salesv2Url + 'Sales/1');//, { params: params });
//         // console.log({url: (this.salesv2Url + 'sales/' + centerNumber)});
//         // console.log({response: response});
//         return response;
//     }
//     getSalesByCountryByYear(country, year): Observable<SalesAmount[]>{
//         let params = new HttpParams();
//         let response =  this.http.get<SalesAmount[]>(this.salesv2Url + 'Sales/' +country + '/' + year, { params: params });
//         //let response =  this.http.get(this.salesv2Url + 'Sales/1');//, { params: params });
//         // console.log({url: (this.salesv2Url + 'sales/' + centerNumber)});
//         // console.log({response: response});
//         return response;
//     }
//     GetYearOverYearSales(centerNumber): Observable<YearOverYearSales[]>{
//         let params = new HttpParams();
//         let response =  this.http.get<YearOverYearSales[]>(this.salesv2Url + 'Sales/GetYearOverYearSalesByCenterId/' + centerNumber, { params: params });
//         return response;
//     }
 
//     getRoyalties(id, royaltyPeriod): Observable<Royalty[]> {
//         let params = new HttpParams();
//         if(royaltyPeriod != null)
//         {
//             params = params.append('royaltyPeriod', royaltyPeriod);
//             return this.http.get<Royalty[]>(this.baseUrl + 'sales/royalties/' + id, { params: params });
//         }
//         return this.http.get<Royalty[]>(this.baseUrl + 'sales/royalties/' + id);
//     }

//     getRoyaltyPeriods(): Observable<Royalty[]> {
//         return this.http.get<Royalty[]>(this.baseUrl + 'sales/royalties/periods');
//     }

//     getRoyaltyByPeriod(royaltyPeriod): Observable<Royalty[]> {
//         let params = new HttpParams();
//         params = params.append('royaltyPeriod', royaltyPeriod);
//         return this.http.get<Royalty[]>(this.baseUrl + 'sales/royalties/', { params: params });
//     }

//     getYTDData():Observable<SalesIndustry[]>{
//         return this.http.get<SalesIndustry[]>(this.baseUrl + 'sales/YearToDate/');
//     }

//     getAllCenterMonthlySales(countryCode, year): Observable<CenterSalesAmount[]>{
//         let params = new HttpParams();
//         if(countryCode != null)
//         {
//             params = params.append('countryCode', countryCode);
//         }
//         if(year != null)
//         {
//             params = params.append('year', year);
//         }

//         return this.http.get<CenterSalesAmount[]>(this.salesv2Url + 'sales/monthly/center', { params: params });
//     }

// }
