import { MasterFranchiseeRoyalty } from './../../_models/mfa/MasterFranchiseeRoyalty';
import { AddMasterFranchiseeRoyalty } from './../../_models/mfa/AddMasterFranchiseeRoyalty';

import { EnviromentService } from './../enviroment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class MasterFranchiseeRoyaltyService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
     }

    add(add: AddMasterFranchiseeRoyalty): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'MasterFranchiseRoyalties', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'MasterFranchiseRoyalties/' + id);
    }

    update(update: MasterFranchiseeRoyalty): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'MasterFranchiseRoyalties/' + update.id, update);
    }

    get(id: number): Observable<MasterFranchiseeRoyalty> {
        return this.http.get<MasterFranchiseeRoyalty>(this.baseUrl + 'MasterFranchiseRoyalties/' + id);
    }

    getMasterFranchiseeRoyaltys(page?, itemsPerPage?, MasterFranchiseeRoyaltyParams?): Observable<PaginatedResult<MasterFranchiseeRoyalty[]>> {
        const paginatedResult: PaginatedResult<MasterFranchiseeRoyalty[]> = new PaginatedResult<MasterFranchiseeRoyalty[]>();
    
        let params = new HttpParams();
    
        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
    
    
        return this.http.get<MasterFranchiseeRoyalty[]>(this.baseUrl + 'MasterFranchiseRoyalties', { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }


    getByMfaId(id: number): Observable<MasterFranchiseeRoyalty[]> {
        return this.http.get<MasterFranchiseeRoyalty[]>(this.baseUrl + 'MasterFranchiseAgreements/' + id + '/MasterFranchiseRoyalties/');
    }

}