import { EnviromentService } from './_services/enviroment.service';
import { Component} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { environment } from './../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  baseUrl = '';
  issuer = '';
  constructor(private oauthService: OAuthService, private enviromentService: EnviromentService) {
    this.issuer = this.enviromentService.getConfig().authUri;
    this.configureWithNewConfigApi();
    this.baseUrl = location.origin;
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure({
      redirectUri: window.location.origin + '/home',
      loginUrl: window.location.origin + '/home',
      clientId: 'fastsigns.client',
      scope: 'openid profile roles api1 FSCentersApi FSEmailAPI FSSalesApi FSGoogleApi FSSalesReportingApi FSReportingApi',
      issuer: this.issuer,
      // requireHttps: true,
      // logoutUrl: window.location.origin,
      // postLogoutRedirectUri: window.location.origin,
      responseType: 'id_token token',
      oidc: true,
    })

    this.oauthService.silentRefreshRedirectUri = window.location.origin + "/assets/silent-refresh.html";
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    //this.oauthService.setStorage(localStorage);
    
    this.oauthService.setupAutomaticSilentRefresh();
    // Load Discovery Document and then try to login the user
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    
  }

}
