import { AlertifyService } from '../_services/alertify.service';
import { Observable ,  of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { MasterFranchiseAgreement } from 'app/_models/mfa/MasterFranchiseAgreement';
import { MasterFranchiseAgreementService } from 'app/_services/mfa/master-franchise-agreement.service';

@Injectable()
export class MasterFranchiseAgreementEditResolver implements Resolve<MasterFranchiseAgreement> {

    constructor(private masterFranchiseAgreementService: MasterFranchiseAgreementService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MasterFranchiseAgreement> {
        return this.masterFranchiseAgreementService.get(route.params['id']).pipe(
            catchError(error => {
                this.alertify.error(error.status + ': ' + error.statusText);
                this.router.navigate(['/mfas']);
                return of(null);
            })
        );
    }
}
