import { SubFranchiseOption } from './../../_models/mfa/SubFranchiseOption';
import { AddSubFranchiseOption } from './../../_models/mfa/AddSubFranchiseOption';

import { EnviromentService } from './../enviroment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class SubFranchiseOptionService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
     }

    add(add: AddSubFranchiseOption): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'SubFranchiseOptions', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'SubFranchiseOptions/' + id);
    }

    update(update: SubFranchiseOption): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'SubFranchiseOptions/' + update.id, update);
    }

    get(id: number): Observable<SubFranchiseOption> {
        return this.http.get<SubFranchiseOption>(this.baseUrl + 'SubFranchiseOptions/' + id);
    }

    getSubFranchiseOptions(page?, itemsPerPage?, SubFranchiseOptionParams?): Observable<PaginatedResult<SubFranchiseOption[]>> {
        const paginatedResult: PaginatedResult<SubFranchiseOption[]> = new PaginatedResult<SubFranchiseOption[]>();
    
        let params = new HttpParams();
    
        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
    
    
        return this.http.get<SubFranchiseOption[]>(this.baseUrl + 'SubFranchiseOptions', { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }


    getByMfaId(id: number): Observable<SubFranchiseOption[]> {
        return this.http.get<SubFranchiseOption[]>(this.baseUrl + 'MasterFranchiseAgreements/' + id + '/SubFranchiseOptions/');
    }

}