import { filter } from 'rxjs/operators';
import { AuthGuard } from './../../shared/auth/auth.guard';
import { OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';
import { Claims } from '../../_models/claims';
import { Router, NavigationEnd } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    display: boolean;
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Home', icon: 'users_circle-08', class: '', display: false},
    { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' , display: false},
    { path: '/centers', title: 'Centers',  icon: 'business_globe', class: '' , display: false},
    { path: '/contacts', title: 'Contacts',  icon: 'business_badge', class: '' , display: false},
    { path: '/insurance', title: 'insurance',  icon: 'files_paper', class: '' , display: false},
    { path: '/agreements-report', title: 'agreements',  icon: 'business_briefcase-24', class: '' , display: false},
    { path: '/compliance-report', title: 'compliance',  icon: 'ui-1_check', class: '' , display: false},
    { path: '/mfas', title: 'MFAs',  icon: 'files_box', class: '' , display: false},
    { path: '/campaign', title: 'campaigns',  icon: 'ui-1_email-85', class: '' , display: false},
    { path: '/configuration', title: 'configuration',  icon: 'ui-2_settings-90', class: '' , display: false},
    { path: '/audits', title: 'Audit Logs',  icon: 'files_single-copy-04', class: '' , display: false},
    { path: '/map', title: 'Center Map',  icon: 'location_map-big', class: '' , display: false},

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  navigationSubscription;

  constructor(private oauthService: OAuthService, private authGuard: AuthGuard, private router: Router) 
  { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
  }

  ngOnInit() 
  {
    if (this.oauthService.hasValidIdToken()) 
    {
        const roles = (<Claims> this.oauthService.getIdentityClaims()).role;
        var corp  = roles.some(x => x == 'fs-corporate-user');
        var franchise = roles.some(x => x =='fs-franchise');
        var admin = roles.some(x => x =='admin');
        var diamondAdmin = roles.some(x => x =='fs-diamond-admin');


        if(franchise)
        {
            ROUTES[0].display = true;
            ROUTES[2].display = true;
            ROUTES[11].display = true;
        }

        if(corp)
        {
            ROUTES[0].display = true;
            ROUTES[1].display = true;
            ROUTES[2].display = true;
            ROUTES[3].display = true;
            ROUTES[4].display = true;
            ROUTES[5].display = true;
            ROUTES[6].display = true;
            ROUTES[11].display = true;
        }

        if(admin || diamondAdmin){
            ROUTES[7].display = true;
            ROUTES[8].display = true;
            ROUTES[9].display = true;
            ROUTES[10].display = true;
        }

    }
    else
    {
        ROUTES[0].display = true;
    }
    this.menuItems = ROUTES.filter(menuItem => menuItem);

  }

  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };

    switchTheme() {
        if ((<any>document).body.hasAttribute('style')) {
            (<any>document).body.removeAttribute('style');
        } else {
            (<any>document).body.style = 'filter: invert(1) grayscale(1);';
        }

    };
}
