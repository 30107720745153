import { Injectable } from '@angular/core';
import { EnviromentService } from './../enviroment.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';
import { AddLetterOfIntent } from 'app/_models/mfa/AddLetterOfIntent';
import { LetterOfIntent } from 'app/_models/mfa/LetterOfIntent';
@Injectable()
export class LetterOfIntentService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
    }

    add(add: AddLetterOfIntent): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'LetterOfIntents', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'LetterOfIntents/' + id);
    }

    update(update: LetterOfIntent): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'LetterOfIntents/' + update.id, update);
    }

    get(id: number): Observable<LetterOfIntent> {
        return this.http.get<LetterOfIntent>(this.baseUrl + 'LetterOfIntents/' + id);
    }

    getLetterOfIntents(page?, itemsPerPage?, LetterOfIntentParams?): Observable<PaginatedResult<LetterOfIntent[]>> {
        const paginatedResult: PaginatedResult<LetterOfIntent[]> = new PaginatedResult<LetterOfIntent[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }


        return this.http.get<LetterOfIntent[]>(this.baseUrl + 'LetterOfIntents', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    return paginatedResult;
                })
            );
    }


    getByMfaId(id: number): Observable<LetterOfIntent[]> {
        return this.http.get<LetterOfIntent[]>(this.baseUrl + 'MasterFranchiseAgreements/' + id + '/LetterOfIntents/');
    }

}
