import { DevelopmentSchedule } from './../../_models/mfa/DevelopmentSchedule';
import { AddDevelopmentSchedule } from './../../_models/mfa/AddDevelopmentSchedule';

import { EnviromentService } from './../enviroment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class DevelopmentScheduleService {
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService) {
        this.baseUrl = enviromentService.getConfig().apiMfa;
     }

    add(add: AddDevelopmentSchedule): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'DevelopmentSchedules', add);
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'DevelopmentSchedules/' + id);
    }

    update(update: DevelopmentSchedule): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'DevelopmentSchedules/' + update.id, update);
    }

    get(id: number): Observable<DevelopmentSchedule> {
        return this.http.get<DevelopmentSchedule>(this.baseUrl + 'DevelopmentSchedules/' + id);
    }

    getDevelopmentSchedules(page?, itemsPerPage?, DevelopmentScheduleParams?): Observable<PaginatedResult<DevelopmentSchedule[]>> {
        const paginatedResult: PaginatedResult<DevelopmentSchedule[]> = new PaginatedResult<DevelopmentSchedule[]>();
    
        let params = new HttpParams();
    
        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
    
    
        return this.http.get<DevelopmentSchedule[]>(this.baseUrl + 'DevelopmentSchedules', { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }


    getByDevelopmentId(id: number): Observable<DevelopmentSchedule[]> {
        return this.http.get<DevelopmentSchedule[]>(this.baseUrl + 'Developments/' + id + '/DevelopmentSchedules/');
    }

}