import { AddGuarantor } from '../_models/AddGuarantor';
import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Contact } from '../_models/Contact';
import { Guarantor } from '../_models/IGuarantor';

@Injectable()
export class GuarantorService {

  baseUrl = '';
  
  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(add: Guarantor): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'guarantors', add);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'guarantors/' + id);
  }

  get(id: number): Observable<Contact> {
    return this.http.get<Contact>(this.baseUrl + '/guarantors/' + id);
  }

  getByCenterIdFinancialId(centerId: number, financialid: number): Observable<AddGuarantor[]>{
      return this.http.get<AddGuarantor[]>(this.baseUrl + 'centers/' + centerId + '/financials/' + financialid + '/guarantors');
  }

}
