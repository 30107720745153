
<footer class="footer">
  <div class="container-fluid">
    <nav>
      <ul>
        <li>
          <a href="#">
            
          </a>
        </li>
        <li>
          <a href="#">
            
          </a>
        </li>
        <li>
          <a href="#">
            
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright">
      &copy;
      {{test | date: 'yyyy'}} - FASTSIGNS
    </div>
  </div>
</footer>
