import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'app/_models/Pagination';
import { Country } from 'app/_models/Country';
import { StateProvince } from 'app/_models/StateProvince';
import { AddCountry } from 'app/_models/AddCountry';
import { AddStateProvince } from 'app/_models/AddStateProvince';
import { Region } from 'app/_models/Region';

@Injectable()
export class CountryStateProvinceService {
    
    baseUrl = '';

    constructor(private http: HttpClient, private enviromentService: EnviromentService, ) { 
        this.baseUrl = enviromentService.getConfig().apiV2Url;
    }

    addCountry(add: AddCountry): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'countries', add);
    }

    deleteCountry(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'countries/' + id);
    }

    updateCountry(update: Country): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'countries/' + update.id, update);
    }

    getCountry(id: number): Observable<Country> {
        return this.http.get<Country>(this.baseUrl + '/countries/' + id);
    }

    getCountries(page?, itemsPerPage?): Observable<PaginatedResult<Country[]>> {
        const paginatedResult: PaginatedResult<Country[]> = new PaginatedResult<Country[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }


        return this.http.get<Country[]>(this.baseUrl + 'countries', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    paginatedResult.result = paginatedResult.result.sort(function(a,b){
                            if(a.name < b.name) { return -1; }
                            if(a. name > b.name) { return 1; }
                            return 0;
                    });
                    return paginatedResult;
                })
            );
    }

    addStateProvince(add: AddStateProvince): Observable<number> {
        return this.http.post<number>(this.baseUrl + 'stateProvinces', add);
    }

    deleteStateProvince(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.baseUrl + 'stateProvinces/' + id);
    }

    updateStateProvince(update: StateProvince): Observable<number> {
        return this.http.put<number>(this.baseUrl + 'stateProvinces/' + update.id, update);
    }

    getStateProvince(id: number): Observable<StateProvince> {
        return this.http.get<StateProvince>(this.baseUrl + '/stateProvinces/' + id);
    }

    getStateProvinces(page?, itemsPerPage?): Observable<PaginatedResult<StateProvince[]>> {
        const paginatedResult: PaginatedResult<StateProvince[]> = new PaginatedResult<StateProvince[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }


        return this.http.get<StateProvince[]>(this.baseUrl + 'stateProvinces', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;
                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }
                    return paginatedResult;
                })
            );
    }


    getStateProvincesByCountryId(countryId: number): Observable<StateProvince[]> {
        return this.http.get<StateProvince[]>(this.baseUrl + 'countries/' + countryId + '/stateProvinces');
    }

    getRegions(): Observable<Region[]> {
        return this.http.get<Region[]>(this.baseUrl + 'regions');
    }

}
