import { Injectable } from '@angular/core';
import { EnviromentService } from './enviroment.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PastOwner } from 'app/_models/PastOwner';

@Injectable()
export class PastOwnerService {
  
  baseUrl = '';
  constructor(private http: HttpClient, private enviromentService: EnviromentService, private oauthService: OAuthService) {
    this.baseUrl = enviromentService.getConfig().apiV2Url;
  }

  add(add: PastOwner): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'pastOwners', add);
  }

  delete(id: number): Observable<boolean> {
      return this.http.delete<boolean>(this.baseUrl + 'pastOwners/' + id);
  }

  update(update: PastOwner): Observable<number> {
      return this.http.put<number>(this.baseUrl + 'pastOwners/' + update.id, update);
  }

  get(id: number): Observable<PastOwner> {
    return this.http.get<PastOwner>(this.baseUrl + '/pastOwners/' + id);
  }

  getByCenterId(centerId: number): Observable<PastOwner[]> {
      return this.http.get<PastOwner[]>(this.baseUrl + 'centers/' + centerId + '/pastOwners');
  }

}
